<template>
    <div>
  
      <v-dialog v-model="dialog"   max-width="900" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card elevation="1" class="ma-0">
          <v-card-title>
            <v-icon size="22" color="info darken-3">fa-print</v-icon>
            <span class="ml-3">เลือกรูปแบบการปริ้น</span>
            <v-spacer/>
            <v-btn icon @click="CPPrintBarcode()"><v-icon color="danger">mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text class="overflow-y-auto">
  
            <v-row>
              <v-col cols="6">
                <v-row>
              <v-col cols="12"   > <h3  style="color:red" class="ml-2">1.ปริ้นบาร์โค้ด</h3></v-col>
              <v-col cols="12" class="mt-2" align="center">
  
                <v-card :elevation="2" width="400">
                  <a @click="OPenTab(0)">
                    <v-card-title class="pa-3">
                      <div class="col-12 line-clamp text-left pa-0 color-black">
                        {{ item.productCode == null || item.productCode == '' ? null : '['+item.productCode+'] ' }} {{ item.name }} {{ item.prop1Description }} {{item.prop2Description }}
                      </div>
                    </v-card-title>
                    <v-card-text class="pa-3 pt-0">
  
                      <VueBarcode :value="item.barcode" height="70" width="2" fontSize= "0" marginTop="1" marginBottom="1" />
                      <v-row class="color-black">
                        <v-col cols="12">
                          {{ item.barcode }}
                        </v-col>
                      </v-row>
  
                      <v-row class="color-black pt-2">
                        <v-col cols="12" align="left">
                          Sku : {{ item.sku }}
                        </v-col>
                        <v-col cols="9" align="left">
                          {{ DateNow() }}
                        </v-col>
                        <v-col cols="3" align="right">
                          {{ item.quantity }}
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </a>
                </v-card>
  
              </v-col>
            </v-row>
              </v-col>
              <v-col cols="6">
                <v-row  >
                  <v-col cols="12" > <h3 style="color:red" class="ml-2">2.ปริ้น LotNo</h3></v-col>
  
                  <v-col cols="12" class="mt-2" align="center">
  
                    <v-card :elevation="2" width="400" >
                      <a @click="OPenTab(1)">
  
                        <v-card-text class="pa-3 color-black">
                          <v-row>
                            <v-col cols="6" align="left" class="color-black">
                              <span>LotNo : {{ item.id }} </span><br>
                              <span>จำนวน : {{ item.quantity }} </span>
  
                            </v-col>
                            <v-col  cols="6">
                              <VueBarcode :value="item.lotNo" height="40" width="2" fontSize= "0" marginTop="7" marginBottom="1" />
                            </v-col>
                          </v-row>
  
                          <v-row  >
                            <v-col cols="12"  align="left">
                              {{!item.barcode ? "" : "barcode" +" : "}} {{ item.barcode }} {{item.barcode && item.sku ? ",": "" }} {{!item.sku ? ""  : "SKU" + " : "}}  {{ item.sku }}
                            </v-col>
                            <v-col cols="12"  align="left">
                              <span v-if="item.productCode!=null && item.productCode!=''"> รหัสสินค้า : {{ item.productCode }}</span>
                              <span v-if="item.productCode!=null && item.productCode!='' && (item.prop1Description || item.prop2Description)"> ,</span>
                              <span v-if="item.prop1Description || item.prop2Description">  {{!item.prop1Description && !item.prop2Description ? "" : "ลักษณะ" + "  : "}}  {{ item.prop1Description }}  {{item.prop2Description }}</span>
                            </v-col>
                            <v-col v-if="item.manufactureDateNow !=null" cols="12"  align="left">
                              วันที่ผลิต :{{ item.manufactureDateNow }}
                            </v-col>
                            <v-col v-if="item.expirationDateNow !=null" cols="12"  align="left">
                             วันที่หมดอายุ : {{ item.expirationDateNow }}
                            </v-col>
                            <v-col cols="12"  align="left">
                              <br> {{ item.name }}
                            </v-col>
                          </v-row>
  
                        </v-card-text>
                      </a>
                    </v-card>
  
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
  
  
            <v-row>
              <v-col cols="6">
                <v-row class="mt-8">
                  <v-col cols="12"   > <h3 style="color:red" class="ml-2">3.ปริ้นรายละเอียดสินค้า</h3></v-col>
  
                  <v-col cols="12" class="mt-2" align="center">
  
                    <v-card :elevation="2" width="400" >
                      <a @click="OPenTab(2)">
                        <v-card-title class="pa-3">
                          <div class="col-12 text-truncate pa-0 color-black">
                            {{ ((item.sku==null || item.sku=="") ? "" : "SKU : ") }} {{ item.sku }}
                          </div>
                        </v-card-title>
                        <v-card-text class="pa-3 pt-0">
  
                          <VueBarcode :value="item.barcode" height="40" width="2" fontSize= "0" marginTop="1" marginBottom="1" />
                          <v-row class="color-black">
                            <v-col cols="12">
                              {{ item.barcode }}
                            </v-col>
                          </v-row>
  
                          <v-row class="color-black py-1">
                            <v-col cols="4" align="left">
                              ชื่อสินค้า :
                            </v-col>
                            <v-col cols="8" align="left">
                              {{ item.name }}
                            </v-col>
  
                            <v-col cols="4" align="left">
                              รหัสสินค้า :
                            </v-col>
                            <v-col cols="8" align="left">
                              {{ item.productCode }}
                            </v-col>
  
                            <v-col cols="4" align="left">
                              ลักษณะ :
                            </v-col>
                            <v-col cols="8" align="left">
                              {{ item.prop1Description }} {{item.prop2Description }}
                            </v-col>
  
                            <v-col cols="4" align="left">
                              RackNo :
                            </v-col>
                            <v-col cols="8" align="left">
                              {{ item.rackNo }}
                            </v-col>
  
                            <v-col cols="4" align="left">
                              Lot name :
                            </v-col>
                            <v-col cols="8" align="left">
                              {{ item.lotName }}
                            </v-col>
  
                            <v-col cols="4" align="left">
                              วันที่ผลิต :
                            </v-col>
                            <v-col cols="8" align="left">
                              {{ item.manufactureDateNow }}
                            </v-col>
  
                            <v-col cols="4" align="left">
                              วันที่หมดอายุ :
                            </v-col>
                            <v-col cols="8" align="left">
                              {{ item.expirationDateNow }}
                            </v-col>
                          </v-row>
  
                          <VueBarcode :value="item.lotNo" height="40" width="2" fontSize= "0" marginTop="7" marginBottom="1" />
                          <v-row class="color-black">
                            <v-col cols="12">
                              LotNo : {{ item.id }}
                            </v-col>
                          </v-row>
  
                        </v-card-text>
                      </a>
                    </v-card>
  
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
  
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
  
    </div>
  </template>
  
  <script>
     import VueBarcode from 'vue-barcode'
    export default {
      props: [
        'item',
        'dialog',
      ],
      components: {
        VueBarcode
      },
      data: () => ({
      }),
      created() {
      },
      methods: {
        OPenTab(type){
          localStorage.setItem('printbarcodetype', type)
          localStorage.setItem('printbarcodeitem', JSON.stringify(this.item))
          window.open("/print-barcode");
        },
        CPPrintBarcode(){
          !this.dialog
          this.$emit('emitprintbarcode', false)
        },
         
      },
    }
  </script>
  
  <style scoped>
    >>>.vue-barcode-element{
      width: 100%  !important;
    }
  </style>
  